import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import dataLayerPush from "../helpers/dataLayerPush";
import forEach from "../helpers/forEach";
var scrollPosition = 0;
var lastViewedArticle = null;
var articlesSeenInView = [];
export default function continuousScroll() {
  var _document$getElements, _document$getElements2;
  if (!("IntersectionObserver" in window)) return;
  var originalArticle = document.getElementsByClassName("article__main")[0];
  var continuousArticles = document.getElementsByClassName("js_continuous-article");
  if (!originalArticle || !(continuousArticles !== null && continuousArticles !== void 0 && continuousArticles.length)) return;
  var originalArticleId = originalArticle.getAttribute("data-id");
  if (!articlesSeenInView.includes(originalArticleId)) {
    articlesSeenInView.push(originalArticleId);
  }
  var menuHeight = (_document$getElements = (_document$getElements2 = document.getElementsByClassName("js_site-header")[0]) === null || _document$getElements2 === void 0 ? void 0 : _document$getElements2.clientHeight) !== null && _document$getElements !== void 0 ? _document$getElements : 0;
  var windowInnerHeight = window.innerHeight - menuHeight;
  var observerIn = new window.IntersectionObserver(viewPortUpdateIn, {
    rootMargin: "0px 0px -".concat(windowInnerHeight, "px 0px")
  });
  var observerOut = new window.IntersectionObserver(viewPortUpdateOut, {
    rootMargin: "-".concat(windowInnerHeight, "px 0px 0px 0px ")
  });
  var entryElements = [originalArticle].concat(_toConsumableArray(continuousArticles));
  forEach(entryElements, function (el) {
    if ((el === null || el === void 0 ? void 0 : el.getAttribute("data-observed")) === "true") return;
    observeElement(el);
  });
  function viewPortUpdateIn(entries) {
    var _entry$boundingClient;
    var entry = getIntersectingEntry(entries);
    if ((entry === null || entry === void 0 || (_entry$boundingClient = entry.boundingClientRect) === null || _entry$boundingClient === void 0 ? void 0 : _entry$boundingClient.y) < 0) return;
    viewPortUpdate(entry);
  }
  function viewPortUpdateOut(entries) {
    var _entry$boundingClient2;
    var entry = getIntersectingEntry(entries);
    if ((entry === null || entry === void 0 || (_entry$boundingClient2 = entry.boundingClientRect) === null || _entry$boundingClient2 === void 0 ? void 0 : _entry$boundingClient2.y) > 0) return;
    viewPortUpdate(entry);
  }
  function getIntersectingEntry(entries) {
    var filteredEntries = entries.filter(function (e) {
      return e.isIntersecting;
    });
    return filteredEntries[filteredEntries.length - 1];
  }
  function viewPortUpdate(entry) {
    var articleEl = entry === null || entry === void 0 ? void 0 : entry.target;
    if (!articleEl) return;
    var url, title;
    if (entry) {
      var _updateElements = updateElements(articleEl),
        newUrl = _updateElements.newUrl,
        newTitle = _updateElements.newTitle;
      url = newUrl;
      title = newTitle;
    }
    var articleId = articleEl.getAttribute("data-id");
    if (!entry) return;
    var scrollDirection = getScrollDirection();
    if (lastViewedArticle === articleId || articleId === originalArticleId && scrollDirection === "down") return;
    updateMetadata(articleEl, articleId, url, title, scrollDirection);
  }
  function getScrollDirection() {
    var direction = document.body.getBoundingClientRect().top >= scrollPosition && scrollPosition !== 0 ? "up" : "down";
    scrollPosition = document.body.getBoundingClientRect().top;
    return direction;
  }
  function observeElement(element) {
    observerIn.observe(element);
    observerOut.observe(element);
    element.setAttribute("data-observed", "true");
  }
}
function updateElements(articleEl) {
  var newUrl = articleEl.getAttribute("data-url");
  var newTitle = articleEl.getAttribute("data-headline-html");
  window.history.replaceState(null, null, newUrl);
  document.title = newTitle;
  return {
    newUrl: newUrl,
    newTitle: newTitle
  };
}
function updateMetadata(articleEl, articleId, url, title, scrollDirection) {
  var _brand$split$, _brand$split, _tags$split, _brand$split$2, _brand$split2, _tags$split2;
  var _articleEl$dataset = articleEl.dataset,
    brand = _articleEl$dataset.brand,
    publicationTime = _articleEl$dataset.publicationTime,
    tags = _articleEl$dataset.tags;

  //old
  var oldPageView = Object.assign({}, window.Di.oldGtmData);
  var environmentInfo = oldPageView.environmentInfo,
    pageInfo = oldPageView.pageInfo;
  if (!articlesSeenInView.includes(articleId)) {
    articlesSeenInView.push(articleId);
  }
  environmentInfo.brand = brand === null || brand === void 0 ? void 0 : brand.split(",");
  pageInfo.accessRestriction = "Locked";
  pageInfo.id.value = articleId;
  pageInfo.page = url;
  pageInfo.pageType = "Article Page";
  pageInfo.title = title;
  pageInfo.sections = ["Nyhetstj\xE4nst ".concat((_brand$split$ = brand === null || brand === void 0 || (_brand$split = brand.split(",")) === null || _brand$split === void 0 ? void 0 : _brand$split[1]) !== null && _brand$split$ !== void 0 ? _brand$split$ : "")];
  pageInfo.publicationDate = publicationTime === null || publicationTime === void 0 ? void 0 : publicationTime.slice(0, 10);
  pageInfo.tags = (_tags$split = tags === null || tags === void 0 ? void 0 : tags.split(",")) !== null && _tags$split !== void 0 ? _tags$split : [];
  pageInfo.trigger = scrollDirection === "up" ? "Virtual Page Load Up" : "Virtual Page Load";
  dataLayerPush(oldPageView);

  //new
  var pageView = Object.assign({}, window.Di.gtmData);
  var content = pageView.content;
  content.contentAccessStatus = "locked";
  content.id.value = articleId;
  content.location = window.location.href;
  content.type = "article";
  content.title = title;
  content.section = ["Nyhetstj\xE4nst ".concat((_brand$split$2 = brand === null || brand === void 0 || (_brand$split2 = brand.split(",")) === null || _brand$split2 === void 0 ? void 0 : _brand$split2[1]) !== null && _brand$split$2 !== void 0 ? _brand$split$2 : "")];
  content.publicationDate = publicationTime === null || publicationTime === void 0 ? void 0 : publicationTime.slice(0, 10);
  content.tags = (_tags$split2 = tags === null || tags === void 0 ? void 0 : tags.split(",")) !== null && _tags$split2 !== void 0 ? _tags$split2 : [];
  content.trigger = scrollDirection === "up" ? "virtual page load up" : "virtual page load";
  content.articleIndex = 1 + articlesSeenInView.indexOf(articleId);
  dataLayerPush(pageView);
  lastViewedArticle = articleId;
}